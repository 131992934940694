<template>
  <div>
    {{ first_email }}
    <template v-if="all_emails.length">
      <v-menu
        content-class="elevation-0"
        v-model="menu"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-y
        tile
        left
      >
        <template v-slot:activator="{ on, attrs }">
          <v-chip
            label
            :ripple="false"
            x-small
            color="white cyan--text"
            v-bind="attrs"
            v-on="on"
          >
            <span class="font-size-16 text-capitalize">
              +{{ all_emails.length }} More...</span
            >
          </v-chip>
        </template>

        <div class="custom-border bg-white d-grid">
          <v-chip
            small
            v-for="(email, index) in all_emails"
            :key="index"
            class="ma-2 text-lowercase font-weight-500"
            color="black lighten-2"
            label
            outlined
          >
            {{ email }}
          </v-chip>
        </div>
      </v-menu>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    emails: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    first_email() {
      return this.emails.length ? this.emails[0] : null;
    },
    all_emails() {
      return this.emails.length ? this.emails.slice(1) : [];
    },
  },
};
</script>
