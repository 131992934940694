<template>
  <v-container fluid>
    <v-row>
      <v-col md="12">
        <table class="width-100">
          <tbody>
            <tr class="custom-border-bottom">
              <th class="custom-border-right" width="150">Serial No.</th>
              <td class="px-3 py-3 font-weight-500">
                {{ detail.warranty_unique_id }}
              </td>
            </tr>
            <tr class="custom-border-bottom">
              <th class="custom-border-right" width="150">Start Date</th>
              <td class="px-3 py-3 font-weight-500">
                {{ formatDate(detail.warranty_start_date) }}
              </td>
            </tr>
            <tr class="custom-border-bottom">
              <th class="custom-border-right" width="150">End Date</th>
              <td class="px-3 py-3 font-weight-500">
                {{ formatDate(detail.warranty_end_date) }}
              </td>
            </tr>
            <tr class="custom-border-bottom">
              <th class="custom-border-right" width="150">Duration</th>
              <td class="px-3 py-3 font-weight-500">{{ durationString }}</td>
            </tr>
            <tr>
              <th class="custom-border-right" width="150">Description</th>
              <td class="px-3 py-3 font-weight-500">
                <read-more
                  class="custom-read-more"
                  more-str="read more"
                  :text="
                    detail.warranty_description
                      ? detail.warranty_description
                      : ''
                  "
                  link="#"
                  less-str="read less"
                  :max-chars="200"
                >
                </read-more>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import moment from "moment-timezone";
import CommonMixin from "@/core/plugins/common-mixin";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin],
  name: "warranty-detail",
  props: {
    detail: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.data = param;
        this.setWarrantyDuration();
      },
    },
  },
  data() {
    return {
      data: new Object(),
      durationString: null,
    };
  },
  methods: {
    setWarrantyDuration() {
      const _this = this;
      let startDate = moment(_this.detail.warranty_start_date);
      let endDate = moment(_this.detail.warranty_end_date);
      let diffInDays = endDate.diff(startDate, "days");

      let result = [];

      let totalDuration = moment.duration(diffInDays, "days");

      let years = totalDuration.get("years");
      if (years > 0) {
        if (years == 1) {
          result.push(years + " Year");
        } else {
          result.push(years + " Years");
        }
      }

      let months = totalDuration.get("months");
      if (months > 0) {
        if (months == 1) {
          result.push(months + " Month");
        } else {
          result.push(months + " Months");
        }
      }

      let days = totalDuration.get("days");
      if (days > 0) {
        if (days == 1) {
          result.push(days + " Day");
        } else {
          result.push(days + " Days");
        }
      }

      let hours = totalDuration.get("hours");
      if (hours > 0) {
        if (hours == 1) {
          result.push(hours + " Hour");
        } else {
          result.push(hours + " Hours");
        }
      }

      let minutes = totalDuration.get("minutes");
      if (minutes > 0) {
        if (minutes == 1) {
          result.push(minutes + " Minute");
        } else {
          result.push(minutes + " Minutes");
        }
      }

      _this.durationString = result.join(" ");
    },
  },
};
</script>
