<template>
  <v-layout>
    <v-col class="custom-content-container pt-0" cols="12" md="12" sm="12">
      <template v-if="false && isDialog">
        <v-row v-for="(row, index) in contactPersons" :key="index">
          <v-col md="4">
            <label
              class="font-size-16 font-weight-500 required"
              for="t-salutation"
              >Salutation</label
            >
            <v-select
              id="t-salutation"
              v-model.trim="row.title"
              :items="salutationList"
              dense
              filled
              :rules="[validateRules.required(row.title, 'salutation')]"
              item-text="text"
              item-value="value"
              item-color="cyan"
              label="Salutation"
              solo
              flat
              color="cyan"
            ></v-select>
          </v-col>
          <v-col md="4">
            <label
              class="font-size-16 font-weight-500 required"
              for="t-first_name"
              >First Name</label
            >
            <v-text-field
              id="t-first_name"
              v-model.trim="row.first_name"
              :rules="[
                validateRules.required(row.first_name, 'first name'),
                validateRules.minLength(row.first_name, 'first name', 2),
                validateRules.maxLength(row.first_name, 'first name', 100),
              ]"
              dense
              filled
              label="First Name"
              solo
              flat
              v-on:keyup="appendDialogDisplayName(index)"
              color="cyan"
              class="required-field"
            ></v-text-field>
          </v-col>
          <v-col md="4">
            <label class="font-size-16 font-weight-500" for="t-last_name"
              >Last Name</label
            >
            <v-text-field
              id="t-last_name"
              v-model.trim="row.last_name"
              :rules="[
                validateRules.minLength(row.last_name, 'last name', 2),
                validateRules.maxLength(row.last_name, 'last name', 100),
              ]"
              dense
              filled
              label="Last Name"
              solo
              flat
              v-on:keyup="appendDialogDisplayName(index)"
              color="cyan"
            ></v-text-field>
          </v-col>
          <v-col md="4">
            <label
              class="font-size-16 font-weight-500 required"
              for="t-display_name"
              >Display Name</label
            >
            <v-text-field
              id="t-display_name"
              v-model.trim="row.display_name"
              :rules="[
                validateRules.required(row.display_name, 'display name'),
                validateRules.minLength(row.display_name, 'display name', 2),
                validateRules.maxLength(row.display_name, 'display name', 100),
              ]"
              dense
              filled
              label="Display Name"
              solo
              flat
              color="cyan"
              class="required-field"
            ></v-text-field>
          </v-col>
          <v-col md="4">
            <label
              class="font-size-16 font-weight-500 required"
              for="t-primary_phone"
              >Phone Number</label
            >
            <PhoneTextField
              id="t-primary_phone"
              v-model="row.primary_phone"
              :value="row.primary_phone"
              required
            >
            </PhoneTextField>
          </v-col>
          <v-col md="4">
            <label
              class="font-size-16 font-weight-500 required"
              for="t-primary_email"
              >Email</label
            >
            <v-text-field
              id="t-primary_email"
              v-model.trim="row.primary_email"
              :rules="[
                validateRules.required(row.primary_email, 'email'),
                validateRules.validEmail(row.primary_email, 'email'),
                validateRules.minLength(row.primary_email, 'email', 2),
                validateRules.maxLength(row.primary_email, 'email', 100),
              ]"
              dense
              filled
              label="Email"
              solo
              flat
              color="cyan"
              class="required-field"
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll"
          style="max-height: 60vh; position: relative"
        >
          <v-layout class="d-block">
            <v-row>
              <v-col class="iv-custom-field pt-0" cols="12" md="12" sm="12">
                <v-container fluid class="pt-0">
                  <v-row>
                    <v-col class="text-right pt-0">
                      <v-btn
                        class="my-2 custom-bold-button white--text"
                        v-on:click="dialog = true"
                        color="cyan"
                        small
                      >
                        Add Contact Person
                      </v-btn>
                    </v-col>
                  </v-row>
                  <table width="100%">
                    <thead>
                      <tr>
                        <th class="simple-table-th">#</th>
                        <th class="simple-table-th">Salutation</th>
                        <th class="simple-table-th">First Name</th>
                        <th class="simple-table-th">Last Name</th>
                        <th class="simple-table-th">Display Name</th>
                        <th class="simple-table-th">Phone Number</th>
                        <th class="simple-table-th">Email</th>
                        <th class="simple-table-th"></th>
                      </tr>
                    </thead>
                    <tbody v-if="contactPersons.length">
                      <tr v-for="(row, index) in contactPersons" :key="index">
                        <td class="cursor-default simple-table-td">
                          {{ index + 1 }}
                        </td>
                        <td
                          class="cursor-default simple-table-td text-capitalize"
                        >
                          {{ row.title }}.
                        </td>
                        <td class="cursor-default simple-table-td">
                          {{ row.first_name }}
                        </td>
                        <td class="cursor-default simple-table-td">
                          {{ row.last_name }}
                        </td>
                        <td class="cursor-default simple-table-td">
                          {{ row.display_name }}
                        </td>
                        <td class="cursor-default simple-table-td">
                          {{ row.primary_phone }}
                        </td>
                        <td class="cursor-default simple-table-td">
                          <ShowEmails :emails="row.emails"></ShowEmails>
                        </td>
                        <td class="simple-table-td">
                          <v-menu
                            top
                            content-class="delete-small-confirm"
                            v-model="row.menu"
                            :close-on-content-click="false"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                class="mx-2"
                                color="red lighten-1"
                                dark
                                fab
                                x-small
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon small dark>
                                  mdi-trash-can-outline</v-icon
                                >
                              </v-btn>
                            </template>
                            <DeleteSmallConfirm
                              :message="`Are you sure, you want to delete ${row.display_name} ?`"
                              v-on:cancel="row.menu = false"
                              v-on:success="removeContactPerson(index)"
                            ></DeleteSmallConfirm>
                          </v-menu>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot v-else>
                      <tr>
                        <td colspan="8">
                          <p
                            class="my-4 row-not-found text-center font-weight-500 font-size-16"
                          >
                            <img
                              :src="$assetURL('media/error/empty.png')"
                              class="row-not-found-image"
                            />
                            Uhh... There are no contact person at the moment.
                          </p>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </v-container>
              </v-col>
            </v-row>
          </v-layout>
        </perfect-scrollbar>
        <CreateContactPerson
          v-model="contactPersons"
          :person-dialog="dialog"
          v-on:close="dialog = false"
        ></CreateContactPerson>
      </template>
    </v-col>
  </v-layout>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import CustomerMixin from "@/core/plugins/customer-mixin";
import DeleteSmallConfirm from "@/view/pages/partials/DeleteSmallConfirm";
import CreateContactPerson from "@/view/pages/customer/create-or-update/Create-Contact-Person";
import PhoneTextField from "@/view/pages/partials/PhoneTextField";
import ShowEmails from "@/view/pages/partials/Show-Emails";

export default {
  name: "contact-persons",
  title: "Customer",
  mixins: [CommonMixin, ValidationMixin, CustomerMixin],
  props: {
    detail: {
      type: Object,
      default: function () {
        return {};
      },
    },
    customer: {
      type: Object,
      default: function () {
        return {};
      },
    },
    isDialog: {
      type: Boolean,
      default: false,
    },
    person: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      dialog: false,
      contactPerson: {
        id: null,
        title: "mr",
        first_name: null,
        last_name: null,
        display_name: null,
        primary_email: null,
        emails: [],
        primary_phone: null,
        will_notified: 1,
        position: null,
        default: 0,
      },
      contactPersons: [],
    };
  },
  watch: {
    contactPersons: {
      deep: true,
      immediate: true,
      handler(param) {
        let result = this.lodash.cloneDeep(param);
        this.$emit("saveContactPerson", result);
      },
    },
    detail: {
      deep: true,
      handler() {
        this.makeDefaultEntry();
      },
    },
  },
  methods: {
    appendDisplayName() {
      const result = [];
      const { first_name, last_name } = this.contactPerson;
      if (first_name) {
        result.push(first_name);
      }
      if (last_name) {
        result.push(last_name);
      }
      this.contactPerson.display_name = result.join(" ");
    },
    appendDialogDisplayName(index) {
      const result = [];
      const { first_name, last_name } = this.contactPersons[index];
      if (first_name) {
        result.push(first_name);
      }
      if (last_name) {
        result.push(last_name);
      }
      this.contactPersons[index].display_name = result.join(" ");
    },
    makeDefaultEntry() {
      const _this = this;
      try {
        let emailData = _this.lodash.filter(
          _this.detail.details,
          function (data) {
            return data.type === 1;
          }
        );
        let contactData = _this.lodash.filter(
          _this.detail.details,
          function (data) {
            return data.type === 2;
          }
        );
        if (_this.detail.first_name) {
          let firstEntry = {
            id: null,
            menu: false,
            title: _this.lodash.toString(_this.detail.salutation),
            first_name: _this.lodash.toString(_this.detail.first_name),
            last_name: _this.lodash.toString(_this.detail.last_name),
            display_name: _this.lodash.toString(_this.createDisplayName()),
            primary_email: _this.lodash.head(emailData)
              ? _this.lodash.toString(_this.lodash.head(emailData).value)
              : null,
            primary_phone: _this.lodash.head(contactData)
              ? _this.lodash.toString(_this.lodash.head(contactData).value)
              : null,
            default: 1,
            will_notified: 1,
            position: null,
          };
          firstEntry.emails = [firstEntry.primary_email];
          _this.contactPersons.splice(0, 1, firstEntry);
        }
      } catch (error) {
        _this.contactPersons.splice(0, 1);
        _this.logError(error);
      }
    },
    createDisplayName() {
      let firstname = "";
      if (this.detail.first_name) {
        firstname = this.detail.first_name.toLowerCase();
      }
      let lastname = "";
      if (this.detail.last_name) {
        lastname = this.detail.last_name.toLowerCase();
      }
      return firstname + lastname;
    },
    pushContactPerson() {
      this.contactPersons.push({
        id: null,
        title: "mr",
        menu: false,
        first_name: null,
        last_name: null,
        display_name: null,
        primary_email: null,
        emails: [],
        primary_phone: null,
        will_notified: 1,
        position: null,
        default: 0,
      });
    },
    removeContactPerson(index) {
      this.contactPersons.splice(index, 1);
    },
  },
  components: {
    ShowEmails,
    PhoneTextField,
    DeleteSmallConfirm,
    CreateContactPerson,
  },
  mounted() {
    if (this.isDialog) {
      if (this.contactPersons.length <= 0) {
        this.contactPersons.push({
          id: null,
          title: "mr",
          menu: false,
          first_name: null,
          last_name: null,
          display_name: null,
          primary_email: null,
          primary_phone: null,
          emails: [],
          will_notified: 1,
          position: null,
          default: 0,
        });
      }
    }
    this.makeDefaultEntry();
    if (this.person > 0) {
      this.contactPersons = [this.getPersonById(this.customer, this.person)];
    }
  },
};
</script>
