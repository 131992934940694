import { GET } from "@/core/services/store/request.module";

export default {
  data() {
    return {
      isDuplicateMode: false,
      transaction_type: null,
      transaction_contact_person_dialog: false,
      transaction_customer: 0,
      transaction_contact_person: 0,
      transaction_property: 0,
    };
  },
  methods: {
    getProperty() {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(GET, { url: "property/" + _this.property })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    resetAll() {
      this.transaction_customer = 0;
      this.transaction_contact_person = 0;
      this.transaction_property = 0;
      this.closeDialog();
    },
    closeDialog() {
      this.transaction_contact_person_dialog = false;
    },
    selectCustomerPerson(person) {
      this.transaction_contact_person = this.lodash.toSafeInteger(person);
      this.transaction_contact_person_dialog = false;
      this.checkJob();
    },
    checkJob() {
      const _this = this;
      let customer = _this.lodash.toSafeInteger(_this.transaction_customer);
      let contact_person = _this.lodash.toSafeInteger(
        _this.transaction_contact_person
      );
      let property = _this.lodash.toSafeInteger(_this.transaction_property);

      if (customer > 0 && contact_person > 0 && property > 0) {
        _this.$router.push(
          _this.getDefaultRoute(_this.transaction_type + ".create", {
            query: {
              customer,
              contact_person,
              property,
            },
          })
        );
      }
    },
    createTransaction(property, param) {
      const _this = this;
      _this.transaction_customer = _this.lodash.toSafeInteger(
        property.customer.id
      );
      _this.transaction_property = _this.lodash.toSafeInteger(property.id);
      _this.transaction_type = _this.lodash.toString(param);

      if (property.contact_person_count > 1) {
        _this.transaction_contact_person_dialog = true;
      }

      if (property.contact_person_count == 1) {
        _this.transaction_contact_person = property.contact_person.id;
      }

      _this.checkJob();
    },
  },
};
