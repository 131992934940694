<template>
  <DetailTemplate
    :customClass="'property-detail detail-page'"
    v-if="getPermission('property:view')"
  >
    <template v-slot:header-title>
      <v-skeleton-loader
        class="custom-skeleton"
        v-if="pageLoading"
        width="400"
        height="28"
        type="text"
      >
      </v-skeleton-loader>
      <h1 class="form-title custom-nowrap-ellipsis text-capitalize">
        {{ getPageTitle }}
      </h1>
    </template>
    <template v-slot:header-action>
      <v-skeleton-loader
        class="custom-skeleton d-inline-block mr-2"
        v-if="pageLoading"
        type="button"
        height="28"
      >
      </v-skeleton-loader>
      <template v-else>
        <v-menu
          v-if="createTransactions.length > 0"
          :close-on-click="true"
          bottom
          content-class="custom-menu-list"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-2 custom-bold-button white--text"
              v-bind="attrs"
              v-on="on"
              color="cyan"
            >
              Create... <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template v-for="(item, index) in createTransactions">
              <v-list-item
                :key="index"
                link
                v-on:click="createTransaction(propertyArr, item.action)"
              >
                <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                  <span class="svg-icon svg-icon-lg svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="
                        $assetURL('media/custom-svg/' + item.action + '.svg')
                      "
                    />
                    <!--end::Svg Icon-->
                  </span>
                </v-list-item-icon>
                <v-list-item-title class="font-size-14 mt-2 mb-2">{{
                  item.title
                }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </template>
      <template v-if="getPermission('property:update')">
        <v-skeleton-loader
          class="custom-skeleton d-inline-block mr-2"
          v-if="pageLoading"
          type="button"
          height="28"
        >
        </v-skeleton-loader>
        <v-btn
          v-else
          class="mx-2 custom-bold-button white--text"
          color="cyan"
          :to="
            getDefaultRoute('property.update', {
              param: { id: property },
              query: { customer: customer },
            })
          "
        >
          <v-icon small left>mdi-pencil</v-icon>
          Edit
        </v-btn>
      </template>
      <v-btn
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="goBack"
      >
        <v-icon small left>mdi-keyboard-backspace</v-icon>
        Back
      </v-btn>
    </template>
    <template v-slot:body>
      <v-tabs
        v-model="propertyTab"
        background-color="transparent"
        color="cyan"
        class="custom-tab-transparent"
      >
        <template v-for="(tab, index) in tabs">
          <v-tab
            class="font-size-16 font-weight-600 px-8"
            :key="index"
            :href="'#' + tab.key"
            :disabled="tab.disabled"
          >
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg
                :src="$assetURL('media/custom-svg/' + tab.key + '.svg')"
              />
              <!--end::Svg Icon-->
            </span>
            {{ tab.title }}
          </v-tab>
        </template>
      </v-tabs>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll"
        style="max-height: 65vh; position: relative"
      >
        <v-tabs-items v-model="propertyTab">
          <v-tab-item v-if="getPermission('property:view')" :value="'property'">
            <v-row class="white-background">
              <v-col cols="6">
                <v-container class="pt-0">
                  <v-card-text class="pt-4 pb-0 font-size-16">
                    <v-skeleton-loader
                      class="custom-skeleton height-30px"
                      v-if="pageLoading"
                      type="text@11"
                    >
                    </v-skeleton-loader>
                    <template v-else>
                      <table class="width-100">
                        <tr>
                          <th
                            class="pb-2 font-weight-medium text-capitalize"
                            width="40%"
                          >
                            Property #
                          </th>
                          <td class="pb-2 font-weight-medium text-capitalize">
                            <Barcode :barcode="getBarcode"></Barcode>
                          </td>
                        </tr>
                        <tr>
                          <th class="pb-5 font-weight-medium text-capitalize">
                            Unit No
                          </th>
                          <td class="pb-5 font-weight-medium text-capitalize">
                            {{ propertyArr.unit_no }}
                          </td>
                        </tr>
                        <tr>
                          <th class="pb-5 font-weight-medium text-capitalize">
                            Address Line 1
                          </th>
                          <td class="pb-5 font-weight-medium text-capitalize">
                            {{ propertyArr.street_1 }}
                          </td>
                        </tr>
                        <tr>
                          <th class="pb-5 font-weight-medium text-capitalize">
                            Address Line 2
                          </th>
                          <td class="pb-5 font-weight-medium text-capitalize">
                            {{ propertyArr.street_2 }}
                          </td>
                        </tr>
                        <tr>
                          <th class="pb-5 font-weight-medium text-capitalize">
                            Postal Code
                          </th>
                          <td class="pb-5 font-weight-medium text-capitalize">
                            {{ propertyArr.zip_code }}
                          </td>
                        </tr>
                        <tr
                          v-if="lodash.isEmpty(propertyArr.country) === false"
                        >
                          <th class="pb-5 font-weight-medium text-capitalize">
                            Country
                          </th>
                          <td class="pb-5 font-weight-medium text-capitalize">
                            {{ propertyArr.country.name }}
                          </td>
                        </tr>
                        <tr v-if="false">
                          <th class="pb-5 font-weight-medium text-capitalize">
                            Latitude
                          </th>
                          <td class="pb-5 font-weight-medium text-capitalize">
                            {{ propertyArr.latitude }}
                          </td>
                        </tr>
                        <tr v-if="false">
                          <th class="pb-5 font-weight-medium text-capitalize">
                            Longitude
                          </th>
                          <td class="pb-5 font-weight-medium text-capitalize">
                            {{ propertyArr.longitude }}
                          </td>
                        </tr>
                        <tr>
                          <th class="pb-5 font-weight-medium text-capitalize">
                            Remarks
                          </th>
                          <td
                            class="pb-5 font-weight-medium text-capitalize"
                            width="500"
                          >
                            {{ propertyArr.remarks }}
                          </td>
                        </tr>
                      </table>
                      <div class="mt-4">
                        <h3
                          class="mb-4 font-weight-600 color-custom-blue custom-border-bottom"
                        >
                          Contact Person
                          <v-chip
                            label
                            small
                            class="mx-4 my-2"
                            color="green"
                            outlined
                            v-if="propertyArr.is_tenant"
                            >Tenant</v-chip
                          >
                        </h3>
                        <table v-if="propertyArr.contact_person" width="100%">
                          <tr>
                            <th
                              valign="middle"
                              class="pb-5 font-weight-medium text-capitalize"
                            >
                              Display Name
                            </th>
                            <td
                              valign="middle"
                              class="pb-5 font-weight-medium text-capitalize"
                            >
                              {{ propertyArr.contact_person.display_name }}
                            </td>
                          </tr>
                          <tr>
                            <th
                              valign="middle"
                              class="pb-5 font-weight-medium text-capitalize"
                            >
                              Email
                            </th>
                            <td
                              valign="middle"
                              class="pb-5 font-weight-medium text-lowercase"
                            >
                              <ShowEmails
                                :emails="propertyArr.contact_person.emails"
                              ></ShowEmails>
                            </td>
                          </tr>
                          <tr>
                            <th
                              valign="middle"
                              class="pb-5 font-weight-medium text-capitalize"
                            >
                              Phone
                            </th>
                            <td
                              valign="middle"
                              class="pb-5 font-weight-medium text-capitalize"
                            >
                              {{ propertyArr.contact_person.primary_phone }}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </template>
                  </v-card-text>
                </v-container>
              </v-col>
              <v-col cols="6">
                <GmapMap ref="mapRef" :center="mapCenter" :zoom="19">
                  <GmapMarker :position="mapCenter" />
                </GmapMap>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item value="property-history">
            <ListingTable :columnCount="3" :dataLoading="historyLoading">
              <template v-slot:thead>
                <thead>
                  <tr>
                    <th class="simple-table-th">#</th>
                    <th class="simple-table-th">Contact Person</th>
                    <th class="simple-table-th">Date Time</th>
                  </tr>
                </thead>
              </template>
              <template v-slot:tbody>
                <tbody class="custom-border-bottom">
                  <template v-if="history.length">
                    <tr v-for="(row, index) in history" :key="index">
                      <td class="simple-table-td">{{ index + 1 }}.</td>
                      <td class="simple-table-td">
                        <v-chip
                          label
                          small
                          class="my-2"
                          color="green"
                          outlined
                          v-if="row.tenant"
                          >Tenant</v-chip
                        >
                        <div class="property-listing-customer">
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Display Name: </b>
                            {{ row.display_name }}
                          </p>
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Email: </b>
                            {{ row.primary_email }}
                          </p>
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Phone: </b>
                            {{ row.primary_phone }}
                          </p>
                        </div>
                      </td>
                      <td class="simple-table-td">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              class="mx-2 my-1 custom-grey-border"
                              color="blue-grey darken-1"
                              text-color="white"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-avatar left>
                                <v-icon>mdi-clock-outline</v-icon>
                              </v-avatar>
                              <p class="m-0 custom-nowrap-ellipsis">
                                {{ formatDateTime(row.added_at) }}
                              </p>
                            </v-chip>
                          </template>
                          <p class="m-0 custom-nowrap-ellipsis">
                            {{ row.created_at }}
                          </p>
                        </v-tooltip>
                      </td>
                    </tr>
                  </template>
                  <tr v-else-if="!historyLoading">
                    <td :colspan="3" class="py-4">
                      <p
                        class="m-0 row-not-found text-center font-weight-500 font-size-16"
                      >
                        <img
                          :src="$assetURL('media/error/empty.png')"
                          class="row-not-found-image"
                        />
                        Uhh... There are no history at the moment.
                      </p>
                    </td>
                  </tr>
                </tbody>
              </template>
            </ListingTable>
          </v-tab-item>
          <v-tab-item v-if="getPermission('job:view')" :value="'job'">
            <Tickets :detail="propertyArr" type="property"></Tickets>
          </v-tab-item>
          <v-tab-item
            v-if="false && getPermission('quotation:view')"
            :value="'quotation'"
          >
            <Quotations :detail="propertyArr" type="property"></Quotations>
          </v-tab-item>
          <v-tab-item v-if="getPermission('invoice:view')" :value="'invoice'">
            <Invoices :detail="propertyArr" type="property"></Invoices>
          </v-tab-item>
          <v-tab-item v-if="getPermission('product:view')" :value="'product'">
            <Products :detail="propertyArr" type="property"></Products>
          </v-tab-item>
          <v-tab-item :value="'warranty'">
            <Warranty :detail="propertyArr" type="property"></Warranty>
          </v-tab-item>
        </v-tabs-items>
      </perfect-scrollbar>
    </template>
    <template v-slot:footer>
      <template v-if="transaction_contact_person_dialog">
        <CustomerPersonDialog
          :customerPersonDialog="transaction_contact_person_dialog"
          :customer="transaction_customer"
          v-on:closeDialog="closeDialog"
          v-on:resetAll="resetAll"
          v-on:selectCustomerPerson="selectCustomerPerson"
        ></CustomerPersonDialog>
      </template>
    </template>
  </DetailTemplate>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import PropertyMixin from "@/core/plugins/property-mixin";
import Barcode from "@/view/pages/partials/Barcode.vue";
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import CustomerPersonDialog from "@/view/pages/partials/Select-Customer-Person.vue";
import Tickets from "@/view/pages/partials/Detail/Tickets";
import Quotations from "@/view/pages/partials/Detail/Quotation";
import Invoices from "@/view/pages/partials/Detail/Invoice";
import Products from "@/view/pages/partials/Detail/Products";
import Warranty from "@/view/pages/partials/Detail/Warranty";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import { GET } from "@/core/services/store/request.module";
import ShowEmails from "@/view/pages/partials/Show-Emails";

export default {
  mixins: [CommonMixin, ValidationMixin, PropertyMixin],
  name: "property-detail",
  title: "Property",
  data() {
    return {
      property: 0,
      customer: 0,
      //propertyTab: null,
      pageLoading: true,
      historyLoading: true,
      deleteDialog: false,
      propertyArr: {},
      history: [],
      tabs: [
        {
          title: "Overview",
          icon: "mdi-phone",
          key: "property",
          disabled: false,
        },
        {
          title: "Property History",
          icon: "mdi-phone",
          key: "property-history",
          disabled: false,
        },
        /*{
          title: "Jobs",
          icon: "mdi-credit-card",
          key: "job",
          disabled: false,
        },*/
        /*{
          title: "Quotations",
          icon: "mdi-account-multiple",
          key: "quotation",
          disabled: false
        },*/
        {
          title: "Invoices",
          icon: "mdi-account-multiple",
          key: "invoice",
          disabled: false,
        },
        /*{
          title: "Products",
          icon: "mdi-credit-card",
          key: "product",
          disabled: false
        },
        {
          title: "Warranty",
          icon: "mdi-credit-card",
          key: "warranty",
          disabled: false
        },*/
        /*{
          title: "History",
          icon: "mdi-credit-card",
          key: "history",
          disabled: false,
        },*/
      ],
      createTransactions: [
        {
          title: "Visit",
          action: "job",
        },
        /*{
          title: "Quotation",
          action: "quotation"
        },*/
        {
          title: "Invoice",
          action: "invoice",
        },
      ],
    };
  },
  components: {
    Barcode,
    ShowEmails,
    ListingTable,
    DetailTemplate,
    CustomerPersonDialog,
    Tickets,
    Quotations,
    Products,
    Warranty,
    Invoices,
  },
  methods: {
    deleteProperty() {
      const _this = this;
      if (!_this.$refs.propertyDeleteForm.validate()) {
        return false;
      }
    },
    getHistories() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: `property/${_this.property}/history` })
        .then(({ data }) => {
          _this.history = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.historyLoading = false;
        });
    },
  },
  mounted() {
    const _this = this;
    _this.checkTransactionPermission();
    _this.getHistories();
    _this.checkTabPermission();
    _this
      .getProperty()
      .then((response) => {
        _this.propertyArr = response;
        _this.customer = response.customer
          ? _this.lodash.toSafeInteger(response.customer.id)
          : 0;
        _this.mapCenter = {
          lat: parseFloat(response.latitude),
          lng: parseFloat(response.longitude),
        };
        _this.$store.dispatch(SET_BREADCRUMB, [
          { title: "Property", route: "property" },
          { title: "Detail" },
          { title: response.barcode },
        ]);
      })
      .catch((error) => {
        _this.logError(error);
        _this.$router.go(-1);
      })
      .finally(() => {
        _this.pageLoading = false;
      });
  },
  created() {
    const _this = this;
    _this.property = _this.$route.params.id;
    if (!_this.property || _this.property <= 0) {
      _this.$router.go(-1);
    }

    if (_this.$route.query && _this.$route.query.tab) {
      _this.propertyTab = _this.$route.query.tab;
    }
  },
  computed: {
    getPageTitle() {
      let result = [];
      if (this.propertyArr && this.propertyArr.customer) {
        result.push(this.propertyArr.customer.display_name);
      }
      /*if(this.propertyArr && this.propertyArr.customer && this.propertyArr.customer.default_person){
        result.push(this.propertyArr.customer.default_person.display_name);
      }*/
      if (this.propertyArr && this.propertyArr.barcode) {
        result.push(this.propertyArr.barcode);
      }
      return result.join(" • ");
    },
    propertyTab: {
      set(val) {
        let query = { ...this.$route.query };
        query.tab = val;
        if (val != this.propertyTab) {
          this.$router.push({ query });
        }
      },
      get() {
        return this.$route.query.tab || "property";
      },
    },
    countryName() {
      let result = "";
      if (this.lodash.isEmpty(this.propertyArr.country) === false) {
        result = this.propertyArr.country.name;
      }
      return result;
    },
    stateName() {
      let result = "";
      if (this.lodash.isEmpty(this.propertyArr.state) === false) {
        result = this.propertyArr.state.name;
      }
      return result;
    },
    cityName() {
      let result = "";
      if (this.lodash.isEmpty(this.propertyArr.city) === false) {
        result = this.propertyArr.city.name;
      }
      return result;
    },
    getStatus() {
      return this.lodash.isEmpty(this.propertyArr) === false
        ? this.propertyArr.activated
        : false;
    },
    getBarcode() {
      return this.lodash.isEmpty(this.propertyArr) === false
        ? this.propertyArr.barcode
        : false;
    },
  },
};
</script>
